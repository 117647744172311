import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, from } from 'rxjs';
import { finalize, map, switchMap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth';
import { environment } from '@env/environment';
import { category, completeBenefit, completeSummary, constraintsDto, DiscriminatorDto, doubleConstraintsDto, Plan, planName, PlanPrice, PerksDto } from './plan.interface';
import { NgxPermissionsService  } from 'ngx-permissions';
import { BaseResponse } from '@app/core/base';
@Injectable({
  providedIn: 'root'
})
export class PlanService {
  onCompleteBenefit: BehaviorSubject<completeBenefit> = new BehaviorSubject(null as any);
  onSaveBenefit: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onSaveSummary: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  onSaveConstraints: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public tenant: number;
  public isB2B: boolean;

  constructor(
    private http: HttpClient,
    private _auth: AuthService,
    private _permission: NgxPermissionsService
  ) {
    this.tenant = this._auth.currentUserValue?.TenantId ?? 0;
    this.isB2B = this._auth.currentUserValue!.IsB2B
    
  }

  getPlans(): Observable<planName[]> {
    return from (this.verificarPermiso('seeAllPlans')).pipe(
      switchMap(hasPermission => {
      if (hasPermission) {
        return this.http.get<planName[]>(`${environment.apiUrl}/plan/planforSelect/${this.tenant}`, { params: { visibility: 'null' } });
      } else {
        return this.http.get<planName[]>(`${environment.apiUrl}/plan/planforSelect/${this.tenant}`, { params: { visibility: this.isB2B ? 'B2B':'B2C' } });
      }
    })
    );
  }

  async verificarPermiso(permiso: string): Promise<boolean> {
    const hasPermission = await this._permission.hasPermission(permiso);
    return hasPermission;
  }

  getAll(visibility?: string): Observable<Plan[]> {
    if (this._auth.currentUserValue?.RoleName === 'Super Admin' || this._auth.currentUserValue?.PermissionRole.includes('allEmisionPlan')){
      return this.http.get<Plan[]>(`${environment.apiUrl}/plan/plan/${this.tenant}`, { params: { visibility : "null" } });
    }else{
      return this.http.get<Plan[]>(`${environment.apiUrl}/plan/plan/${this.tenant}`, { params: { visibility: visibility ? visibility : "null" } });
    }
  }

  getAllWithoutPrices(visibility?: string): Observable<Plan[]> {
    return this.http.get<Plan[]>(`${environment.apiUrl}/plan/plan/${this.tenant}`, { params: { visibility: visibility ? visibility : "null" } });
  }

  getPlanById(id: number): Observable<Plan> {
    return this.http.get<Plan>(`${environment.apiUrl}/plan/plan/byId/${id}`);
  }

  getPlanSummaryById(id: number): Observable<completeSummary[]> {
    return this.http.get<completeSummary[]>(`${environment.apiUrl}/form/Forms/GetPlanSummaryByPlanId/${id}`);
  }

  CreatePlanSummary(body: any) {
    return this.http.post(`${environment.apiUrl}/form/Forms/CreatePlanSummary`, body).pipe(finalize(() => this.onSaveSummary.next(true)));
  }
  RemovePlanSummary(id: number) {
    return this.http.delete(`${environment.apiUrl}/form/Forms/RemovePlanSummary/${id}`).pipe(finalize(() => this.onSaveSummary.next(true)));;
  }

  UpdatePlanSummary(body: any) {
    return this.http.put(`${environment.apiUrl}/form/Forms/UpdatePlanSummary`, body).pipe(finalize(() => this.onSaveSummary.next(true)));;
  }

  getPrices(planId: number, tenantId: number, CustomerId: number, UserId: number): Observable<PlanPrice[]> {
    return this.http.get<PlanPrice[]>(`${environment.apiUrl}/plan/PlanPrices/${planId}/${tenantId}/${CustomerId}/${UserId}`);
  }

  createPlan(body: any) {
    return this.http.post(`${environment.apiUrl}/plan/plan`, body);
  }

  updatePlan(id: number, body: any) {
    return this.http.put(`${environment.apiUrl}/plan/plan/${id}`, body);
  }

  deletePlan(id: number) {
    return this.http.delete(`${environment.apiUrl}/plan/plan/${id}`);
  }

  restorePlan(id: number) {
    return this.http.put(`${environment.apiUrl}/plan/plan/Restore/${id}`, id);
  }

  uploadimage(image: File): Observable<any> {
    let formParams = new FormData();
    formParams.append('file', image);
    formParams.append('public_id', image.name);
    formParams.append('api_key', '736764343757325');
    formParams.append('upload_preset', 'tas_preset');
    formParams.append('folder', 'TAS/list-plan');

    return this.http.post(`https://api.cloudinary.com/v1_1/pervolare-org/upload`, formParams);
  }

  getBenefitsByPlan(planId: number, TenantId: number | undefined): Observable<completeBenefit[]> {
    return this.http.get<completeBenefit[]>(`${environment.apiUrl}/plan/Benefit/ByTenant/${TenantId}`).pipe(map(data => data.filter(plan => plan.PlanId == planId)));
  }

  getCategoires(TenantId: number | undefined): Observable<category[]> {
    return this.http.get<category[]>(`${environment.apiUrl}/plan/Categories/ByTenant/${TenantId}`);
  }

  createBenefit(body: any) {
    return this.http.post(`${environment.apiUrl}/plan/Benefit`, body).pipe(finalize(() => this.onSaveBenefit.next(true)));
  }

  updateBenefit(body: any) {
    return this.http.put(`${environment.apiUrl}/plan/Benefit`, body).pipe(finalize(() => this.onSaveBenefit.next(true)));

  }

  deleteBenefit(body: any) {
    return this.http.delete(`${environment.apiUrl}/plan/Benefit`, { body: body });
  }

  restoreBenefit(body: any) {
    return this.http.put(`${environment.apiUrl}/plan/Benefit/Restore`, body);
  }

  getDiscriminators(): Observable<DiscriminatorDto> {
    return this.http.get<DiscriminatorDto>(`${environment.apiUrl}/plan/ConstrainDiscriminator/Constrain`);
  }

  getConstrains(planId: number): Observable<constraintsDto> {
    return this.http.get<constraintsDto>(`${environment.apiUrl}/plan/ConstrainByPlan/${planId}`);
  }

  createConstraints(body: any) {
    return this.http.post(`${environment.apiUrl}/plan/CreateConstrainPlan/Create`, body)
      .pipe(finalize(() => this.onSaveConstraints.next(true)));;

  }

  deleteConstraints(constrainId: number, userId: number) {
    return this.http.delete(`${environment.apiUrl}/plan/Constrain/Delete/${constrainId}/${userId}`)
      .pipe(finalize(() => this.onSaveConstraints.next(true)));;
  }

  getDoubleConstraints(planId: number): Observable<doubleConstraintsDto[]> {
    return this.http.get<doubleConstraintsDto[]>(`${environment.apiUrl}/plan/DoubleConstrainByPlan/${planId}`);
  }

  GetDiscountConstraints(planId: number): Observable<doubleConstraintsDto[]> {
    return this.http.get<doubleConstraintsDto[]>(`${environment.apiUrl}/plan/DiscountConstrainByPlan/${planId}`);
  }

  createDoubleConstraints(body: any) {
    return this.http.post(`${environment.apiUrl}/plan/CreateDoubleConstrain/Create`, body)
      .pipe(finalize(() => this.onSaveConstraints.next(true)));

  }
  deleteDoubleConstraints(constrainId: number, userId: number) {
    return this.http.delete(`${environment.apiUrl}/plan/DoubleConstrain/Delete/${constrainId}/${userId}`)
      .pipe(finalize(() => this.onSaveConstraints.next(true)));
  }

  deleteDiscountConstraints(constrainId: number, userId: number) {
    return this.http.delete(`${environment.apiUrl}/plan/DiscountConstrain/Delete/${constrainId}/${userId}`)
      .pipe(finalize(() => this.onSaveConstraints.next(true)));
  }

  getPerks(planId: number): Observable<PerksDto[]> {
    return this.http.get<PerksDto[]>(`${environment.apiUrl}/dashboard/DashBoard/Perk/${planId}`)
      .pipe(map((response: any) => JSON.parse(response.result)));
  }

  clonePlan(body: any):Observable<BaseResponse<number>> {
    return this.http.post<BaseResponse<number>>(`${environment.apiUrl}/plan/ClonePlan`, body);
  }

  cloneSummaries(body: any):Observable<BaseResponse<number>> {
    return this.http.post<BaseResponse<number>>(`${environment.apiUrl}/form/Forms/ClonePlanSummary`, body);
  }
}
