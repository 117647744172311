import { Injectable } from "@angular/core";
import { IPermissionRepository } from "../../domain/repositories";
import { PermissionMapper } from ".";
import { Observable } from "rxjs";
import { PermissionEntity } from "../../domain/entities";
import { map } from "rxjs/operators";
import { BaseResponse } from "@app/core/base";
import { createPermissionDto, restorePermissionDto, updatePermissionDto } from "../models";
import { IPermissionDataSource } from "../datasource";

@Injectable({
    providedIn: 'root',
})
export class PermissionRepository implements IPermissionRepository {

    constructor(
        private dataSource: IPermissionDataSource,
        private mapper: PermissionMapper
    ) { }

    public getAll(tenantId: number): Observable<PermissionEntity[]> {
        return this.dataSource.getAll(tenantId)
            .pipe(map(a => a.map(this.mapper.mapFrom)));
    }

    public create(input: createPermissionDto): Observable<BaseResponse<number>> {
        return this.dataSource.create(input);
    }

    public update(id: number, input: updatePermissionDto): Observable<BaseResponse<number>> {
        return this.dataSource.update(id, input);
    }

    public delete(userId: number, id: number): Observable<BaseResponse<number>> {
        return this.dataSource.delete(userId, id);
    }

    public restore(input: restorePermissionDto): Observable<BaseResponse<number>> {
        return this.dataSource.restore(input);
    }
}