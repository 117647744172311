import { Injectable } from "@angular/core";
import { BaseResponse } from "@app/core/base";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { RoleEntity } from "../../domain/entities";
import { IRolesRepository } from "../../domain/repositories";
import { IRolesDataSource } from "../datasource";
import { RolesMapper } from "../mappers";
import { InputRolesDto } from "../models";

@Injectable({
    providedIn: 'root',
})
export class RolesRepository implements IRolesRepository {

    constructor(
        private dataSource: IRolesDataSource,
        private mapper: RolesMapper
    ) { }

    getAvailableRoles(userId: number): Observable<RoleEntity[]> {
        return this.dataSource.getAvailableRoles(userId)
            .pipe(map(a => a.map(this.mapper.mapFrom)));;
    }

    public getAll(tenantId: number): Observable<RoleEntity[]> {
        return this.dataSource.getAll(tenantId)
            .pipe(map(a => a.map(this.mapper.mapFrom)));
    }

    public create(input: InputRolesDto): Observable<BaseResponse<number>> {
        return this.dataSource.create(input);
    }

    public update(id: number, input: InputRolesDto): Observable<BaseResponse<number>> {
        return this.dataSource.update(id, input);
    }

    public delete(id: number): Observable<BaseResponse<number>> {
        return this.dataSource.delete(id);
    }

    public restore(id: number, userId: number): Observable<BaseResponse<number>> {
        return this.dataSource.restore(id, userId);
    }
}