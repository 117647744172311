import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseResponse } from "@app/core/base";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IRolesDataSource } from "..";
import { InputRolesDto, RolesDto } from "../../models";

@Injectable({
    providedIn: 'root',
})
export class RolesDataSource implements IRolesDataSource {
    constructor(private client: HttpClient) { }


    getAvailableRoles(userId: number): Observable<RolesDto[]> {
        return this.client.get<BaseResponse<RolesDto[]>>(`${environment.apiUrl}/roles/User/${userId}/GetAvailableRoles`)
            .pipe(map(response => response.result));;
    }

    getAll(tenantId: number): Observable<RolesDto[]> {
        return this.client.get<BaseResponse<RolesDto[]>>(`${environment.apiUrl}/roles/role/${tenantId}`)
            .pipe(map(response => response.result));
    }

    create(input: InputRolesDto): Observable<BaseResponse<number>> {
        return this.client.post<BaseResponse<number>>(`${environment.apiUrl}/roles/role/`, input);
    }

    update(id: number, input: InputRolesDto): Observable<BaseResponse<number>> {
        return this.client.put<BaseResponse<number>>(`${environment.apiUrl}/roles/role/${id}`, input);
    }

    delete(id: number): Observable<BaseResponse<number>> {
        return this.client.delete<BaseResponse<number>>(`${environment.apiUrl}/roles/role/${id}`);
    }

    restore(id: number, userId: number): Observable<BaseResponse<number>> {
        return this.client.put<BaseResponse<number>>(`${environment.apiUrl}/roles/role/restore/${id}/${userId}`, {});
    }
}