import { Injectable } from "@angular/core";
import { Mapper } from "@app/core/base";
import { PermissionEntity } from "../../domain/entities";
import { PermissionModel } from "../models";

@Injectable({
    providedIn: 'root',
})
export class PermissionMapper extends Mapper<PermissionModel, PermissionEntity> {
    mapFrom(param: PermissionModel): PermissionEntity {
        return {
            Id: param.id,
            Name: param.name,
            Description: param.description,
            IsDeleted: param.isDeleted,
            CreationTime: param.creationTime,
            parentId:param.parentId,
            parentPermissionName: param.parentPermissionName,
            childPermissions:null
        };
    }

    mapTo(param: PermissionEntity): PermissionModel {
        return {
            id: param.Id,
            name: param.Name,
            description: param.Description,
            isDeleted: param.IsDeleted,
            creationTime: param.CreationTime,
            tenantId: 0,
            parentId:param.parentId,
            parentPermissionName: param.parentPermissionName,
            childPermissions:null
        };
    }
}


