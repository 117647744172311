import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { groupBy, map, mergeMap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ResponseWithHeaders, GeneralReport, ResponseForChart, SystemOldTasReport,ResponseForChartTasOld, metaTasOld, RequestReport, OptionFields } from '../interface/generalReport.interface';
import { BaseResponse } from '@app/core/base';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient) { }

  GeneralReport(body:RequestReport): Observable<BaseResponse<ResponseWithHeaders>>{
    return this.http.post<BaseResponse<ResponseWithHeaders>>(`${environment.apiUrl}/reports/GeneralReport`, body)
  }

  ModificationReport(body:RequestReport): Observable<BaseResponse<ResponseWithHeaders>>{
    return this.http.post<BaseResponse<ResponseWithHeaders>>(`${environment.apiUrl}/reports/ModificationReport`, body);
  }

  TransversalReport(body:RequestReport): Observable<BaseResponse<ResponseWithHeaders>>{
    return this.http.post<BaseResponse<ResponseWithHeaders>>(`${environment.apiUrl}/reports/TransversalReport`, body)
  }

  ChartQuotesReport(body:any): Observable<GeneralReport>{
    return this.http.post<ResponseForChart>(`${environment.apiUrl}/admin/Adm/GeneralTransversalReport`, body)
    .pipe(mergeMap(data => data.Content))
  }

  ChartPurchaseReport(body:any): Observable<GeneralReport>{
    return this.http.post<ResponseForChart>(`${environment.apiUrl}/admin/Adm/GeneralReport`, body)
    .pipe(mergeMap(data => data.Content))
  }

  // PREVIOUS TAS CHARTS SYSTEM OLD

  ChartTasOldReport(): Observable<SystemOldTasReport>{
    // return this.http.get<any>(`${environment.apiUrlTasOld}/directus-master/public/traveler-assistance/items/asegurados?meta=total_count%2Cresult_count&limit=200&offset=0&fields=id.*%2Ccodexterno.*%2Cfecha.*%2Cid_user.*%2Cplan.*%2Ccoddesc.*%2Cid_user&sort=-np&q=VIAJES`);
    return this.http.get<ResponseForChartTasOld>(`${environment.apiUrlTasOld}/directus-master/public/traveler-assistance/items/asegurados?meta=total_count%2Cresult_count&limit=200&offset=0&fields=id.*%2Ccodexterno.*%2Cfecha.*%2Cid_user.*%2Cplan.*%2Ccoddesc.*%2Cvalor.*%2Cid_user&sort=-np&q=VIAJES`)
    .pipe(mergeMap(data => data.data));
  }
  CountChartTasOldReport(): Observable<metaTasOld>{
    // return this.http.get<any>(`${environment.apiUrlTasOld}/directus-master/public/traveler-assistance/items/asegurados?meta=total_count%2Cresult_count&limit=200&offset=0&fields=id.*%2Ccodexterno.*%2Cfecha.*%2Cid_user.*%2Cplan.*%2Ccoddesc.*%2Cid_user&sort=-np&q=VIAJES`);
    return this.http.get<ResponseForChartTasOld>(`${environment.apiUrlTasOld}/directus-master/public/traveler-assistance/items/asegurados?meta=total_count%2Cresult_count&limit=200&offset=0&fields=id.*%2Ccodexterno.*%2Cfecha.*%2Cid_user.*%2Cplan.*%2Ccoddesc.*%2Cvalor.*%2Cid_user&sort=-np&q=VIAJES`)
    .pipe(mergeMap(data => data.meta));
  }

  //ENDPOINT NEW FOR DASHBOARD
  DashboardTas(body: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}/admin/Adm/DataDashBoard`, body)
    .pipe(mergeMap(data => data));
  }

  //Informacion de headers para seleccion del usuario
  HeadersReports(): Observable<OptionFields[]>{
    return this.http.get<OptionFields[]>('../data/optionsFields.json');
  }
}
