import { BaseResponse } from "@app/core/base";
import { Observable } from "rxjs";
import { createPermissionDto, restorePermissionDto, updatePermissionDto } from "../../infrastructure/models";
import { PermissionEntity } from "../entities";

export abstract class IPermissionRepository {
    abstract getAll(tenantId: number): Observable<PermissionEntity[]>;
    abstract create(input: createPermissionDto): Observable<BaseResponse<number>>;
    abstract update(id: number, input: updatePermissionDto): Observable<BaseResponse<number>>;
    abstract delete(userId: number, id: number): Observable<BaseResponse<number>>;
    abstract restore(input: restorePermissionDto): Observable<BaseResponse<number>>;
}