import { Injectable } from "@angular/core";
import { Mapper } from "@app/core/base";
import { RoleEntity } from "../../domain/entities";
import { RolesDto } from "../models";

@Injectable({
    providedIn: 'root',
})
export class RolesMapper extends Mapper<RolesDto, RoleEntity> {
    mapFrom(param: RolesDto): RoleEntity {
        return {
            Id: param.id,
            Name: param.name ?? '',
            Description: param.description ?? '',
            UsersCount: param.usersCount,
            IsDeleted: param.isDeleted,
            CreationTime: param.creationTime,
            FatherRoleId: param.fatherRoleId,
            FatherRoleName: param.fatherRoleName,
            Permissions: param.permissions ? param.permissions.map(p => {
                return { Id: p.id, Name: p.name, IsDeleted: p.isDeleted, Description: '', CreationTime: new Date(),parentId: p.parentId , parentPermissionName:p.parentPermissionName, childPermissions:null}
            }) : []
        };
    }

    mapTo(param: RoleEntity): RolesDto {
        return {
            id: param.Id,
            name: param.Name,
            description: param.Description,
            usersCount: param.UsersCount,
            isDeleted: param.IsDeleted,
            creationTime: param.CreationTime,
            fatherRoleId: param.FatherRoleId,
            fatherRoleName: param.FatherRoleName,
            permissions: param.Permissions.map(p => {
                return { id: p.Id, name: p.Name, isDeleted: p.IsDeleted,parentId: p.parentId, parentPermissionName: p.parentPermissionName,childPermissions:null}
            })
        };
    }
}
