import { BaseResponse } from '@app/core/base';
import { Observable } from 'rxjs';
import { createPermissionDto, PermissionModel, restorePermissionDto, updatePermissionDto } from '../../models';


export abstract class IPermissionDataSource {
  abstract getAll(tenantId: number): Observable<PermissionModel[]>;
  abstract create(input: createPermissionDto): Observable<BaseResponse<number>>;
  abstract update(id: number, input: updatePermissionDto): Observable<BaseResponse<number>>;
  abstract delete(userId:number, id: number): Observable<BaseResponse<number>>;
  abstract restore(input: restorePermissionDto): Observable<BaseResponse<number>>;
}