import { BaseResponse } from "@app/core/base";
import { Observable } from "rxjs";
import { InputRolesDto, RolesDto } from "../../models";

export abstract class IRolesDataSource {
    abstract getAll(tenantId: number): Observable<RolesDto[]>;
    abstract delete(id: number): Observable<BaseResponse<number>>;
    abstract getAvailableRoles(userId: number): Observable<RolesDto[]>;
    abstract create(input: InputRolesDto): Observable<BaseResponse<number>>;
    abstract restore(id: number, userId: number): Observable<BaseResponse<number>>;
    abstract update(id: number, input: InputRolesDto): Observable<BaseResponse<number>>;
}