import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { BaseResponse } from '@app/core/base';
import { IPermissionDataSource } from '../interfaces/permission-datasource.interface';
import { createPermissionDto, PermissionModel, restorePermissionDto, updatePermissionDto } from '../../models';

const service = 'permission';

@Injectable({
    providedIn: 'root',
})
export class PermissionDataSource implements IPermissionDataSource {
    constructor(private client: HttpClient) { }

    getAll(tenantId: number): Observable<PermissionModel[]> {
        return this.client.get<BaseResponse<PermissionModel[]>>(`${environment.apiUrl}/${service}/permission/${tenantId}`)
            .pipe(map(response => response.result));
    }

    create(input: createPermissionDto): Observable<BaseResponse<number>> {
        return this.client.post<BaseResponse<number>>(`${environment.apiUrl}/${service}/permission`, input);
    }

    update(id: number, input: updatePermissionDto): Observable<BaseResponse<number>> {
        return this.client.put<BaseResponse<number>>(`${environment.apiUrl}/${service}/permission/${id}`, input);
    }

    delete(userId:number, id: number): Observable<BaseResponse<number>> {
        return this.client.delete<BaseResponse<number>>(`${environment.apiUrl}/${service}/permission/${userId}/${id}`);
    }

    restore(input: restorePermissionDto): Observable<BaseResponse<number>> {
        return this.client.put<BaseResponse<number>>(`${environment.apiUrl}/${service}/permission/restore`, input);
    }

}