import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { Tenant } from '..';
import { map } from 'rxjs/operators';
import { BaseResponse } from '@app/core/base';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<Tenant[]> {
    return this.http.get<BaseResponse<Tenant[]>>(`${environment.apiUrl}/tenant/tenant`).pipe(map(t => t.result));
  }

  getById(id:number): Observable<Tenant>{
    return this.http.get<BaseResponse<Tenant>>(`${environment.apiUrl}/tenant/tenant/${id}`).pipe(map(t => t.result))
  }
}
